import { APIUrls } from '../api/apiUrls';
import { Empresa } from './empresa';
import { JSONConverters } from './JSONConverters';

export class ConfiguracionRequisicion {
    constructor(
        public id: number,
        public costoTransporte: string,
        public factorAMarginar: string,
        public tasaCambio: string,
        public vehiculos: string,
        public vias: string,
        public paisOrigen: string,
        public factorEspecial: string,
        public semaforoDiasPedidosAbiertos: string,
        public semaforoDiasHastaEntrega: string,
        public semaforoDiasPendienteEntrega: string,
        public correosClientes: string,
        // Relaciones
        public EmpresaId: number,
    ){
        this.cargarArrays();
    }

    jsonConverts: JSONConverters = new JSONConverters();
    costoTransporteArray: any[];
    factorAMarginarArray: any[];
    tasaCambioArray: any[];
    vehiculosArray: any[];
    viasArray: any[];
    paisOrigenArray: any[];
    factorEspecialArray: any[];
    semaforoDiasPedidosAbiertosArray: any[];
    semaforoDiasHastaEntregaArray: any[];
    semaforoDiasPendienteEntregaArray: any[];
    correosClientesArray: any[];

    Empresa: Empresa;

    cargarArrays() {
        this.costoTransporteArray = this.costoTransporte ? JSON.parse(this.costoTransporte) : [];
        this.factorAMarginarArray = this.factorAMarginar ? JSON.parse(this.factorAMarginar) : [];
        this.tasaCambioArray = this.tasaCambio ? JSON.parse(this.tasaCambio) : [];
        this.vehiculosArray = this.vehiculos ? JSON.parse(this.vehiculos) : [];
        this.viasArray = this.vias ? JSON.parse(this.vias) : [];
        this.paisOrigenArray = this.paisOrigen ? JSON.parse(this.paisOrigen) : [];
        this.factorEspecialArray = this.factorEspecial ? JSON.parse(this.factorEspecial) : [];
        this.semaforoDiasPedidosAbiertosArray = this.semaforoDiasPedidosAbiertos ? JSON.parse(this.semaforoDiasPedidosAbiertos) : [];
        this.semaforoDiasHastaEntregaArray = this.semaforoDiasHastaEntrega ? JSON.parse(this.semaforoDiasHastaEntrega) : [];
        this.semaforoDiasPendienteEntregaArray = this.semaforoDiasPendienteEntrega ? JSON.parse(this.semaforoDiasPendienteEntrega) : [];
        this.correosClientesArray = this.correosClientes ? JSON.parse(this.correosClientes) : [];
    }

    guardar() {
        // Convertir los arrays a JSON
        this.costoTransporte = JSON.stringify(this.costoTransporteArray);
        this.factorAMarginar = JSON.stringify(this.factorAMarginarArray);
        this.tasaCambio = JSON.stringify(this.tasaCambioArray);
        this.vehiculos = JSON.stringify(this.vehiculosArray);
        this.vias = JSON.stringify(this.viasArray);
        this.paisOrigen = JSON.stringify(this.paisOrigenArray);
        this.factorEspecial = JSON.stringify(this.factorEspecialArray);
        this.semaforoDiasPedidosAbiertos = JSON.stringify(this.semaforoDiasPedidosAbiertosArray);
        this.semaforoDiasHastaEntrega = JSON.stringify(this.semaforoDiasHastaEntregaArray);
        this.semaforoDiasPendienteEntrega = JSON.stringify(this.semaforoDiasPendienteEntregaArray);
        this.correosClientes = JSON.stringify(this.correosClientesArray);
    }
}